<template>
  <div class="coming-soon">
    <div class="content">
      <h1 class="animated-title">Nizosoft</h1>
      <p>We build amazing apps for Android & iOS, websites, and provide microservices like Payment and Auth services.</p>
      <h2 class="animated-coming-soon">Coming Soon</h2>
      <div class="services">
        <div class="service animated-service">
          <i class="fas fa-mobile-alt"></i>
          <p>App Development</p>
        </div>
        <div class="service animated-service">
          <i class="fas fa-laptop-code"></i>
          <p>Website Development</p>
        </div>
        <div class="service animated-service">
          <i class="fas fa-server"></i>
          <p>Microservices</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComingSoon',
}
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

.coming-soon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #6f2794;
  color: #fff;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  perspective: 1000px; /* for 3D effects */
}

.content {
  max-width: 600px;
  width: 100%;
  padding: 20px;
  transform: rotateY(0deg) rotateX(0deg);
  transition: transform 0.5s ease;
}

.coming-soon:hover .content {
  transform: rotateY(10deg) rotateX(10deg); /* subtle 3D tilt on hover */
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  animation: title-glow 3s infinite alternate;
}

@keyframes title-glow {
  0% {
    text-shadow: 2px 2px 8px rgba(255, 255, 255, 0.3);
  }
  100% {
    text-shadow: 2px 2px 20px rgba(255, 255, 255, 0.8);
  }
}

p {
  font-size: 1.1rem;
  margin-bottom: 30px;
}

h2 {
  font-size: 2rem;
  margin-bottom: 30px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  animation: coming-soon-glow 3s infinite alternate;
}

@keyframes coming-soon-glow {
  0% {
    text-shadow: 2px 2px 8px rgba(255, 255, 255, 0.3);
  }
  100% {
    text-shadow: 2px 2px 20px rgba(255, 255, 255, 0.8);
  }
}

.services {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.service {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: rotateY(0deg) rotateX(0deg);
  transition: transform 0.5s ease;
}

.service i {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #f6920b;
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
  animation: icon-bounce 2s infinite ease-in-out;
}

@keyframes icon-bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.service p {
  font-size: 1rem;
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
  animation: service-glow 3s infinite alternate;
}

@keyframes service-glow {
  0% {
    text-shadow: 1px 1px 8px rgba(255, 255, 255, 0.3);
  }
  100% {
    text-shadow: 1px 1px 20px rgba(255, 255, 255, 0.8);
  }
}

/* Responsive styles */
@media (min-width: 768px) {
  .services {
    flex-direction: row;
    justify-content: space-around;
  }
}

@media (min-width: 1024px) {
  h1 {
    font-size: 3.5rem;
  }

  p {
    font-size: 1.3rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  .service i {
    font-size: 2.5rem;
  }

  .service p {
    font-size: 1.1rem;
  }
}
</style>
